import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

import authHeader from "../../auth-header";

import EditInventory from "../inventory/edit";

import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import * as XLSX from "xlsx";

import OwnerSelect from "../../components/ownerSelect";
import ConfirmRequest from "../../components/confirm";

const InventorySearch = (props) => {
  const [inventory, setInventory] = useState([]);
  const [searchState, setSearchState] = useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [editItem, setEditItem] = useState([]);
  const [aggregate, setAggregate] = useState(0);
  const [quantity, setQuantity] = useState("any");
  const [editResults, setEditResults] = useState(true);
  const [owner, setOwner] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [searchType, setSearchType] = useState(0);
  const searchRef = useRef();
  const tableRef = useRef();

  const [searchItems, setSearchItems] = useState([
    { title: "All", text: "All Fields", field: "" },
    { title: "Part Number", text: "Part Number", field: "PartNum" },
    { title: "ALU PO Numberrr", text: "ALU PO Number", field: "ALUPONum" },
    { title: "Customer PO Number", text: "Customer PO Number", field: "PONum" },
    { title: "SO Number", text: "SO Number", field: "SONum" },
  ]);

  useEffect(() => {
    if (props.customer === "34") {
      setSearchItems([
        { title: "All", text: "All Fields", field: "" },
        { title: "Part Number", text: "Part Number", field: "PartNum" },
        { title: "ALU PO Number", text: "ALU PO Number", field: "ALUPONum" },
        {
          title: "Customer PO Number",
          text: "Customer PO Number",
          field: "PONum",
        },
        { title: "SO Number", text: "SO Number", field: "SONum" },
      ]);
    } else if (props.customer === "41") {
      setSearchItems([
        { title: "All", text: "All Fields", field: "" },
        { title: "Part Number", text: "Part Number", field: "PartNum" },
        { title: "Vendor", text: "Vendor", field: "Vendor" },
        {
          title: "Customer PO Number",
          text: "Customer PO Number",
          field: "PONum",
        },
        { title: "SO # / Bin #", text: "SO # / Bin #", field: "SONum" },
      ]);
    } else {
      setSearchItems([
        { title: "All", text: "All Fields", field: "" },
        { title: "Part Number", text: "Part Number", field: "PartNum" },
        { title: "Vendor", text: "Vendor", field: "Vendor" },
        {
          title: "Customer PO Number",
          text: "Customer PO Number",
          field: "PONum",
        },
        { title: "SO Number", text: "SO Number", field: "SONum" },
      ]);
    }
  }, [props.customer]);

  const totalQuantity = () => {
    return inventory.reduce((sum, cur) => sum + parseInt(cur.Quantity), 0);
  };

  const getInventory = () => {
    setSearchState(1);
    setInventory([]);
    axios
      .get("/api/inventory/search", {
        params: {
          owner: owner,
          value: searchRef.current.value,
          field: searchItems[searchType].field,
          aggregate: aggregate,
          quantity: quantity,
          customer: props.customer,
        },
        headers: authHeader(),
      })
      .then(function (response) {
        // console.log(response.data)
        setInventory(response.data);
        if (response.data.length > 0) setSearchState(0);
        else setSearchState(2);
        setEditResults(aggregate === "1" ? false : true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const searchHandler = (e) => {
    e.preventDefault();
    getInventory();
  };

  const saveData = (savedData) => {
    console.log("saveData", savedData);
    console.log("editItem", editItem);
    // return;
    axios
      .post(
        "/api/inventory/update",
        {
          row: savedData.row,
          rack: savedData.rack,
          shelf: savedData.shelf,
          serialNum: savedData.serialNum,
          aluNum: savedData.ALUPONum,
          poNum: savedData.PONum,
          soNum: savedData.SONum,
          description: savedData.description,
          quantity: savedData.quantity,
          skuNum: editItem.SKUNum,
          inboundSKU: editItem.InboundSKU,
          owner: editItem.Owner,
          partNum: editItem.PartNum,
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveDataHandler = (savedData) => {
    console.log("saveDataHandler", savedData);
    // return;
    // console.log("editItem", editItem);
    setInventory(
      inventory.map((item) =>
        item.SKUNum === editItem.SKUNum
          ? {
              ...item,
              Row: savedData.row,
              Rack: savedData.rack,
              Shelf: savedData.shelf,
              SerialNum: savedData.serialNum,
              ALUPONum: savedData.ALUPONum,
              PONum: savedData.PONum,
              SONum: savedData.SONum,
              Description: savedData.description,
              Quantity: savedData.quantity,
              Edited: true,
            }
          : item
      )
    );
    // make call to db
    saveData(savedData);
  };

  const editPart = (item) => {
    // console.log("editPart", item);
    setEditItem(item);
    setModalShow(true);
  };

  const exportDataHandler = () => {
    const table_elt = tableRef.current;

    const workbook = XLSX.utils.table_to_book(table_elt, {
      dateNF: "yyyy-MM-DD hh:mm:ss",
    });

    XLSX.writeFile(workbook, "Report_Inventory.xlsx");
  };

  const deleteSKU = (SKUNum) => {
    // console.log('delete SKU!', SKUNum);
    axios
      .post(
        "/api/inventory/deleteSKU",
        {
          skuNum: SKUNum,
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        setInventory((prevInventory) => {
          const updatedInventory = prevInventory.filter((item) => item.SKUNum !== SKUNum);
          return updatedInventory;
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    setConfirmDelete(false);
  };

  const InventoryResult = () => (
    <Container fluid>
      <Table ref={tableRef} responsive size="sm">
        <thead className="table-light">
          <tr>
            {editResults && <th>SKU</th>}
            <th>Part #</th>
            <th>Owner</th>
            {editResults && <th>{props.customer === "41" ? "Material ID" : "Serial #"}</th>}
            <th>{props.customer === "34" ? "ALU #" : "Vendor"}</th>
            <th>PO #</th>
            <th>{props.customer === "41" ? "SO # / Bin #" : "SO #"}</th>
            {editResults && (
              <>
                <th>Row</th>
                <th>Rack</th>
                <th>Shelf</th>
              </>
            )}
            <th>Description</th>
            <th>Stock</th>
            {props.role === 2 && <th></th>}
          </tr>
        </thead>
        <tbody>
          {inventory.map((item, index) => (
            <tr key={index + 1} style={item.Edited ? { background: "lightyellow" } : {}}>
              {editResults && (
                <td
                  onClick={() => editPart(item)}
                  style={{
                    cursor: "pointer",
                    textDecorationLine: "underline",
                    color: "blue",
                  }}
                >
                  {item.SKUNum}
                </td>
              )}
              <td>{item.PartNum}</td>
              <td>{item.Owner}</td>
              {editResults && <td>{item.SerialNum} </td>}
              <td>{item.ALUPONum} </td>
              <td>{item.PONum} </td>
              <td>{item.SONum} </td>
              {editResults && (
                <>
                  <td>{item.Row} </td>
                  <td>{item.Rack} </td>
                  <td>{item.Shelf} </td>
                </>
              )}
              <td>{item.Description} </td>
              <td>{item.Quantity} </td>
              {props.role === 2 && (
                <td>
                  {item.InboundSKU !== null ? (
                    <Button onClick={() => setConfirmDelete(item.SKUNum)}>Delete</Button>
                  ) : (
                    <Button disabled onClick={() => setConfirmDelete(item.SKUNum)}>
                      Delete
                    </Button>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="5" />
            {editResults && <td colSpan="5" />}
            <th>Total</th>
            <th>{totalQuantity()}</th>
          </tr>
        </tfoot>
      </Table>
    </Container>
  );

  return (
    <>
      <Container fluid>
        <h4 style={{ backgroundColor: "#F0F0F0" }}>Inventory Search</h4>
        <Form onSubmit={searchHandler}>
          <Row className="mb-3">
            <Form.Group as={Col} xl={2} lg={3} md={4}>
              <Form.Label>Owner</Form.Label>
              <OwnerSelect setOwnerHandler={setOwner} customer={props.customer} selectAll={true} />
            </Form.Group>
            <Form.Group as={Col} className="align-self-end" xl={4} lg={5} sm={8}>
              <InputGroup>
                <DropdownButton onSelect={(e) => setSearchType(e)} variant="outline-secondary" title={searchItems[searchType].title} id="input-group-dropdown-1">
                  {searchItems.map((item, i) => (
                    <Dropdown.Item key={i} eventKey={i}>
                      {item.text}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <Form.Control type="text" ref={searchRef} />
                {searchState !== 1 ? (
                  <Button type="submit">Search</Button>
                ) : (
                  <Button>
                    <Spinner animation="border" size="sm" />
                  </Button>
                )}
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} lg={2} sm={2}>
              <Form.Label>Listing</Form.Label>
              <Form.Select onChange={(e) => setAggregate(e.target.value)}>
                <option value="0">Individual</option>
                <option value="1">Aggregate</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} xl={1} lg={2} sm={2}>
              <Form.Label>Quantity</Form.Label>
              <Form.Select onChange={(e) => setQuantity(e.target.value)}>
                <option value="any">Any</option>
                <option value="= 0">= 0</option>
                <option value="> 0">{">"} 0</option>
                <option value="< 0">{"<"} 0</option>
              </Form.Select>
            </Form.Group>
            <Col className="align-self-end">
              <Button variant="outline-primary" onClick={exportDataHandler}>
                Save Data (.xlsx)
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
      {searchState === 2 && (
        <Container fluid>
          <Alert variant="danger">No Results</Alert>
        </Container>
      )}
      {inventory.length > 0 && <InventoryResult />}

      <EditInventory
        skunum={editItem.SKUNum}
        inboundSKU={editItem.InboundSKU}
        row={editItem.Row}
        rack={editItem.Rack}
        shelf={editItem.Shelf}
        serialNum={editItem.SerialNum}
        vendor={editItem.ALUPONum}
        poNum={editItem.PONum}
        soNum={editItem.SONum}
        description={editItem.Description}
        quantity={editItem.Quantity}
        showModal={modalShow}
        onSaveData={saveDataHandler}
        onHide={() => setModalShow(false)}
        role={props.role}
        customer={props.customer}
      />

      <ConfirmRequest showModal={confirmDelete} message={`Are you sure you want delete SKU ${confirmDelete}?`} onConfirm={() => deleteSKU(confirmDelete)} onHide={() => setConfirmDelete(false)} />
    </>
  );
};

export default InventorySearch;
